import React from 'react';
import PropTypes from 'prop-types';

import GridContainer from '../../imported/GridContainer';
import WPPageHeader from './WPPageHeader';
import { testAttributes } from './Constants';
import { build } from '../../../Constants.json';


import { content } from './WPPagePage.module.scss';
import '../../../css/styles/global-page.module.scss';

export const fngetMorePosts = (posts, index, newIndex) =>
    posts.slice(index, newIndex);

const WPPagePage = ({
    gridTitle,
    postInfo,
    fullContainer,
    fullPageWidth,
    showTitle,
    showBreadCrumbs,
    showPostGrid,
}) => (
    <div
        itemProp="articleBody"
        className={`site-content ${content} isfull-${fullPageWidth}`}
        id="primary"
        data-test={testAttributes.WPPAGE_MAIN}
    >
        <article
            className="globalArticle"
            itemScope
            itemType="https://schema.org/Article"
            data-test={testAttributes.WPPAGE_ARTICLE}
        >
            {showBreadCrumbs && (
                <WPPageHeader
                    breadcrumbs={postInfo.breadcrumbs}
                    fullContainer={fullContainer}
                />
            )}
            {showTitle && (
                <h1
                    className={`page-title ${
                        fullContainer && 'lmb-wide-block'
                    }`}
                >
                    {postInfo.infoPost.title}
                </h1>
            )}
            {postInfo.infoPost.content}
        </article>

        {showPostGrid && (
            <>
                <h2
                    data-test={testAttributes.WPPAGE_TITLE_READMORE}
                    className="titlePostRelated"
                >
                    {gridTitle}
                </h2>
                <GridContainer
                    queryArgs={{
                        posts: postInfo.postsRelated,
                        total_found: postInfo.postsRelated.length,
                    }}
                    type="button"
                    gridLimit={build.defaults.POSTS_PER_PAGE}
                    getMorePosts={fngetMorePosts}
                    btnLabel={postInfo.btnLabel}
                    data-test={testAttributes.WPPAGE_GRIDCONTAINER}
                    containerId={build.defaults.GRID_CONTAINER_ID}
                />
            </>
        )}
    </div>
);
WPPagePage.propTypes = {
    /** postInfo, data from GraphQL  */
    postInfo: PropTypes.objectOf(PropTypes.any).isRequired,
    /** title, for grid container default Read More  */
    gridTitle: PropTypes.string,
    /** fullContainer, for container width */
    fullContainer: PropTypes.bool,
    /** fullPageWidth, add a class to container when element has a fullwidth */
    fullPageWidth: PropTypes.bool,
    /** showTitle, Show article title */
    showTitle: PropTypes.bool,
    /** ShowBreadCrumbs, Show breadcrumbs */
    showBreadCrumbs: PropTypes.bool,
    /** ShowPostGrid, Show post grid default true */
    showPostGrid: PropTypes.bool,
};

WPPagePage.defaultProps = {
    gridTitle: testAttributes.gridLabel,
    fullContainer: false,
    fullPageWidth: false,
    showTitle: true,
    showBreadCrumbs: true,
    showPostGrid: true,
};

export default WPPagePage;
