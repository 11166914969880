import React from 'react';
import PropTypes from 'prop-types';
import { GatsbyImage } from 'gatsby-plugin-image';
import { testAttributes, featuredImageFallback } from '../Constants';
import ReadingTime from '../../../simple/ReadingTime';
import Linker from '../../../simple/Linker';
import {
    LatestMediaCard
} from '../Cards.module.scss';
import {
    StoryCardContainer,
    cardContent,
    cardTitle,
    wpPostImageContainer,
    wpPostImage,
    cardByline
} from './StoryCard.module.scss'

const StoryCard = ({featuredImage, title, author, minutes, link, titleStory}) => (
    <div
        className={`${LatestMediaCard} ${StoryCardContainer}`}
        itemScope
        itemType="https://schema.org/Article"
        data-test={testAttributes.STORY_CARD}
    >
        <h2 data-test={testAttributes.STORY_CARD_TITLE}>{titleStory}</h2>
        <div className={cardContent}>
            <Linker href={link} className={wpPostImageContainer}>
                {featuredImage ? (
                        <GatsbyImage
                            image={
                                featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData
                            }
                            className={wpPostImage}
                            alt={title}
                            itemProp="image"
                            data-test={testAttributes.STORY_THUMBNAIL}
                        />
                ) : (
                        <img
                            src={featuredImageFallback.src}
                            alt={title}
                            itemProp="image"
                            data-test={testAttributes.STORY_THUMBNAIL}
                        />
                )}
            </Linker>
            <div className={cardTitle}>
                <Linker href={link}><h3 itemProp="headline" data-test={testAttributes.STORY_TITLE}>
                    {title}
                </h3></Linker>
                <div className={cardByline}>
                    <span itemProp="author" data-test={testAttributes.STORY_AUTHOR}>
                        {author}
                    </span>
                    <ReadingTime minutes={minutes} />
                </div>
            </div>
        </div>
    </div>
)

StoryCard.propTypes = {
    /** Image; Set URL for any image would you like to test */
    featuredImage: PropTypes.objectOf(PropTypes.object),
    /** Author; Name of the author  */
    author: PropTypes.string.isRequired,
    /** Title; Set title of the card */
    title: PropTypes.string.isRequired,
    /** Link - URL of post */
    link: PropTypes.string.isRequired,
    /** Reading time in minutes */
    minutes: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    /** Story Card title, e.g. 'Story of the Week' */
    titleStory: PropTypes.string.isRequired
};

StoryCard.defaultProps = {
    featuredImage: null,
    minutes: 0,
};

export default StoryCard;
