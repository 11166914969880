import React from 'react';
import PropTypes from 'prop-types';

import { testAttributes } from '../Constants';
import Breadcrumb from '../../../simple/Breadcrumb';

const WPPageHeader = ({ breadcrumbs, fullContainer }) => 
    (
        <div className={`breadcrumbContainer ${fullContainer && 'lmb-wide-block'}`}>
            <Breadcrumb
                breadcrumbs={breadcrumbs}
                data-test={testAttributes.WPPAGE_BREADCRUMBS}
                className="breadcrumb"
            />
        </div>
    )
;

WPPageHeader.propTypes = {
    /** WPPagePage, data from GraphQL  */
    breadcrumbs: PropTypes.arrayOf(PropTypes.any).isRequired,
    /** fullContainer, wide or narrow container */
    fullContainer: PropTypes.bool,
};

WPPageHeader.defaultProps = {
    fullContainer: false,
}

export default WPPageHeader;
