/* eslint-disable react/prop-types */
import React from 'react';
import LatestMedia from '../../../../components/composite/LatestMedia';
import {latestMediaBlockClassName, latestMediaContainerClassName, storyCardClassName, videoCardClassName } from '../Constants'

export const extractTitles = children =>
{
    let titleStory=''; let titleVideo='';
    const containerNode = children.find( child => child.attribs?.class?.split(' ').includes(latestMediaContainerClassName) )
    if ( !containerNode ) return {titleStory, titleVideo};
    const h2StoryNode = containerNode.children.find( child => child.attribs?.class?.split(' ').includes( storyCardClassName) );
    if ( h2StoryNode )
    {
        h2StoryNode.children.forEach( child =>
        {
            if ( child.name !== 'span' ) return;
            titleStory = child.children.find( grandchild => grandchild.type === 'text' )?.data
        })
    }
    const h2VideoNode = containerNode.children.find( child => child.attribs?.class?.split(' ').includes( videoCardClassName) );
    if ( h2VideoNode )
    {
        h2VideoNode.children.forEach( child =>
        {
            if ( child.name !== 'span' ) return;
            titleVideo = child.children.find( grandchild => grandchild.type === 'text' )?.data
        })
    }
    return {titleStory, titleVideo}
}

const toLatestMediaParser = ({story,video}) => 
{
    const latestMediaParser = () => ({name, type, attribs, children}) =>
    {
        if ( type !== 'tag' ) return null;
        if ( !name || name !== 'div' ) return null;
        if ( !attribs.class?.split(' ').includes(latestMediaBlockClassName) ) return null;
        const {titleStory, titleVideo} = extractTitles( children );
        return <LatestMedia 
            story={story}
            video={video}
            titleStory={titleStory}
            titleVideo={titleVideo}
        />
    }

    return () => ({
        fn: latestMediaParser,
        callback: () => 
        {},
        return: {}
    })
}
export default toLatestMediaParser;
