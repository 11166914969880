/* eslint-disable no-param-reassign */
/**
 * 7/20/22 - WP images in editorial content, don't render on re-hydrate.
 * Reason unknown; ticket for this fix = SEOP-1450, and related git thread = https://github.com/gatsbyjs/gatsby/issues/34187
 * This is a workaround = function that should be invoked on useEffect at first mount.
 * gatsby-source-wordpress should find all wp-inline-image's and set src / srcset & style, but it doesn't on re-hydration.
 */
const asyncWpInlineImageWorkaround = async () => 
{
    if (!document) return;
    const unrenderedWPImages = document.querySelectorAll('[data-wp-inline-image][data-srcset]');
    unrenderedWPImages.forEach( wpImg => 
    {
        wpImg.src = wpImg.src || wpImg.dataset.src;
        wpImg.srcset = wpImg.srcset || wpImg.dataset.srcset;
        wpImg.style.opacity = 1;
        delete wpImg.dataset.src;
        delete wpImg.dataset.srcset;
    })
}

const wpInlineImageWorkaround = () => 
{
    asyncWpInlineImageWorkaround();
}

export default wpInlineImageWorkaround;
export {asyncWpInlineImageWorkaround};