/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import {
    bodyClass,
    bodyClassPrefix,
    titleRelated,
    bodyHomeClass,
} from './Constants';
import Layout from '../../components/composite/Layout';
import Seo from '../../components/simple/seo';
import WPPagePage from '../../components/pages/WPPagePage';
import { filterAndSortRelatedPosts } from '../../utilities/gridCardFilters';

import processQueried from './helpers/processQueried';
import parseLayoutProps from '../common/parse/parseLayoutProps';
import wpInlineImageWorkaround from '../../utilities/wpInlineImageWorkaround';
import useLocation from '../../utilities/useLocation'

/* following imports are GraphQL fragments, they are used within the page query */
import {
    menuNodes,
    menuItemNodes,
    pageData,
    siteMetadata,
    postCardData,
    guideCardData,
} from '../../utilities/fragments';

const WpPageContainer = ({ data, location, isPreview, pageContext }) => 
{
    useLocation( location );
    useEffect(wpInlineImageWorkaround, []);

    const { footerProps, headerProps } = parseLayoutProps(data, location);
    const { postArgs, headArgs } = processQueried(data, location, [], pageContext);

    return (
        <Layout
            headerProps={headerProps}
            footerProps={footerProps}
            isPreview={isPreview}
        >
            <Seo
                helmetProps={headArgs}
                bodyClass={`${
                    postArgs.isHomepage ? bodyHomeClass : bodyClass
                } ${bodyClassPrefix}${data.post?.databaseId}`}
            />
            <WPPagePage
                postInfo={postArgs}
                showTitle={postArgs.showTitle}
                fullContainer={postArgs.fullContainer}
                fullPageWidth={postArgs.fullPageWidth}
                gridTitle={titleRelated}
                showBreadCrumbs={postArgs.showBreadCrumbs}
                showPostGrid={postArgs.showPostGrid}
            />
        </Layout>
    );
};

WpPageContainer.propTypes = {
    data: PropTypes.objectOf(PropTypes.any).isRequired,
    isPreview: PropTypes.bool,
    location: PropTypes.objectOf(PropTypes.any).isRequired,
    pageContext: PropTypes.objectOf(PropTypes.any).isRequired
};

WpPageContainer.defaultProps = {
    isPreview: false,
};

export default WpPageContainer;

export const pageQuery = graphql`
    query BlogPageById($id: String!) {
        site {
            ...siteMetadata
        }
        post: wpPage(id: { eq: $id }) {
            ...pageData
        }
        headerMenuNav: allWpMenuItem(
            filter: { locations: { eq: MENU_1 }, parentDatabaseId: { eq: 0 } }
        ) {
            ...menuItemNodes
        }
        footerMenuPolicy: wpMenu(locations: { eq: MENU_2 }) {
            menuItems {
                ...menuNodes
            }
        }
        footerMenuCol1: wpMenu(locations: { eq: FOOTER_COL_1 }) {
            menuItems {
                ...menuNodes
            }
        }
        footerMenuCol2: wpMenu(locations: { eq: FOOTER_COL_2 }) {
            menuItems {
                ...menuNodes
            }
        }
        relatedPosts: allWpPost {
            edges {
                node {
                    ...postCardFields
                }
            }
        }
        relatedGuides: allWpGuide {
            edges {
                node {
                    ...guideCardFields
                }
            }
        }
    }
`;
