import React from 'react';
import PropTypes from 'prop-types';
import StoryCard from './StoryCard';
import VideoCard from './VideoCard';
import parseHTML from '../../../utilities/parseHTML'

import {testAttributes, defaultTitleStory, defaultTitleVideo} from './Constants'
import {
    LatestMediaComponent
} from './LatestMedia.module.scss';

const LatestMedia = ({
    story,
    video,
    titleStory,
    titleVideo
}) => 
{
    const videoPlayer = React.useMemo(() => parseHTML( video.html ), [video.html] )
    return (
        <section data-test={testAttributes.LATEST_MEDIA} className={`lmb-wide-block ${LatestMediaComponent}`}>
            <StoryCard {...story} titleStory={titleStory} minutes={story.readingtime} />
            <VideoCard title={video.title} player={videoPlayer} titleVideo={titleVideo} />
        </section>
    )
}

LatestMedia.propTypes = {
    /** Story object with properties like any post Grid card */
    story: PropTypes.objectOf(PropTypes.any).isRequired,
    /** Video object with title and player html */
    video: PropTypes.objectOf(PropTypes.any).isRequired,
    /** Story card title, e.g. 'Story of the Week' */
    titleStory: PropTypes.string,
    /** Video card title, e.g. 'Video of the Week' */
    titleVideo: PropTypes.string
}
LatestMedia.defaultProps = {
    titleStory: defaultTitleStory,
    titleVideo: defaultTitleVideo
}
export default LatestMedia;