import * as HeaderConstants from '../../composite/Header/Constants';
import * as FooterConstants from '../../composite/Footer/Constants';
import * as GridContainerConstants from '../../composite/GridContainer/Constants';
import parseHTML from '../../../utilities/parseHTML';

export const testAttributes = {
    WPPAGE_MAIN: 'wppage_main',
    WPPAGE_BREADCRUMBS: 'wppage_breadcrumbs',
    WPPAGE_ARTICLE: 'wppage_article',
    WPPAGE_TITLE_READMORE: 'wppage_title_readmore',
    WPPAGE_GRIDCONTAINER: 'wppage_grid_container',
};

export const sampleAttributes = {
    databaseId: 1111,
    fullPageWidth: true,
    showTitle: false,
    fullContainer: true,
    infoPost: {
        title: 'Mortgage Tools',
        content: parseHTML(`
        <section class="wp-block-group lmb-blockpattern-title-cluster lmb-wide-block">
            <div class="wp-block-group__inner-container">
                <h1 class="wp-block-post-title">Mortgage Calculators and Tools</h1>
                <img class="cdm-ornamental-image-img wp-block-cdm-ornamentalimage-ornamentalimage" data-test="ornamental_image_img" data-fullurl="https://loremflickr.com/g/660/550/illustration,dog/all" src="https://loremflickr.com/g/660/550/illustration,dog/all" alt="Randomly chosen drawing of a dog"/>
                <div class="wp-block-group lmb-title-cluster-subtitle">
                    <div class="wp-block-group__inner-container">
                        <div class="wp-block-group__inner-container">
                            <p>Buying or refinancing a home? Use our free mortgage tools to check your budget, figure out your monthly payment, and more.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="wp-block-group lmb-blockpattern-cta-guides lmb-wide-block">
        <h2>Lorem ipsum dolor sit amet, consectetur adipiscing elit</h2>
        <div class="wp-block-columns">
        <div class="wp-block-column">
        <h3>Lorem ipsum dolor sit amet</h3>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec eget molestie erat. Nunc ultricies erat eros</p>
        <div class="wp-block-cdm-highlightbullet-highlightbullet"><aside class="cdm-highlight-bullet" data-test="highlight_bullet"><a href="https://www.lowermybills.com/">Pellentesque cursus ex non ligula tincidunt semper</a></aside></div>
        <p></p>
        </div>
        <div class="wp-block-column">
        <h3>Lorem ipsum dolor sit amet</h3>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec eget molestie erat. Nunc ultricies erat eros</p>
        <div class="wp-block-cdm-highlightbullet-highlightbullet"><aside class="cdm-highlight-bullet" data-test="highlight_bullet"><a href="https://www.lowermybills.com/">Pellentesque cursus ex non ligula tincidunt semper</a></aside></div>
        </div>
        </div>
        </section>

        <section class="wp-block-group lmb-blockpattern-find-refinance lmb-wide-block">
        <div class="wp-block-group__inner-container">
            <h2>Find a Refinance Lender </h2>
            <img
                class="cdm-ornamental-image-img wp-block-cdm-ornamentalimage-ornamentalimage"
                data-test="ornamental_image_img"
                data-fullurl="https://loremflickr.com/800/533/illustration,dog/all"
                src="https://loremflickr.com/800/533/illustration,dog/all"
                alt="Homeowner and inspector examine a home's interior window."
            />

            <div class="wp-block-group lmb-find-refinance-subtitle">
                <div class="wp-block-group__inner-container">
                    <div class="wp-block-group__inner-container">
                        <p>The right refinance lender can help you reduce what you’re paying for your mortgage. Get started with LowerMyBills.
                        </p>

                        <div class="wp-block-buttons lmb-cta-buttons-large">
                            <div class="wp-block-button">
                                <a class="wp-block-button__link">COMPARE MORTGAGES</a>
                            </div>
                        </div>

                        
                    </div>
                </div>
            </div>
        </div>
        </section>
        <div>
        <h2>Common Homebuying Questions</h2>
        <h3>How much money do you need to buy a house? </h3>
        <p>To get a mortgage and buy a house, you’ll typically pay a percentage of the purchase price upfront. The 20% down payment is a well-known rule of thumb — and a significant hurdle to homeownership — but it’s also a myth.  In fact, qualified borrowers may put as little as 3% down , and no down payment is required for certain government programs . If you know what loans you’re eligible for, you can determine the down payment you need to buy a home. <p>
        </div>
        `),
        content2: parseHTML(`
        <section class="wp-container-4 wp-block-group lmb-blockpattern-fullwidth-title-cluster lmb-fullwidth-block">
            <div class="wp-block-group__inner-container">
                <div class="wp-container-3 wp-block-group lmb-fullwidth-title-cluster-container">
                    <div class="wp-block-group__inner-container">
                        <h1 class="wp-block-post-title">Title test</h1>
                        <img class="cdm-ornamental-image-img wp-block-cdm-ornamentalimage-ornamentalimage" data-test="ornamental_image_img" data-fullurl="http://localhost/wp-content/uploads/2022/06/Frame.png" src="https://loremflickr.com/g/660/550/illustration,dog/all" alt="Ornamental image">
                        <div class="wp-container-2 wp-block-group lmb-title-cluster-subtitle">
                            <div class="wp-block-group__inner-container">
                                <p>
                                    Learn how to get the most out of your loan, whether you’re buying a home for the first time or seeking general mortgage advice and resources.
                                </p>
                                <div class="wp-container-1 wp-block-buttons">
                                    <div class="wp-block-button lmb-cta-buttons-small">
                                        <a class="wp-block-button__link">COMPARE LENDERS</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="wp-block-group lmb-blockpattern-cta-guides lmb-wide-block">
        <h2>Lorem ipsum dolor sit amet, consectetur adipiscing elit</h2>
        <div class="wp-block-columns">
        <div class="wp-block-column">
        <h3>Lorem ipsum dolor sit amet</h3>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec eget molestie erat. Nunc ultricies erat eros</p>
        <div class="wp-block-cdm-highlightbullet-highlightbullet"><aside class="cdm-highlight-bullet" data-test="highlight_bullet"><a href="https://www.lowermybills.com/">Pellentesque cursus ex non ligula tincidunt semper</a></aside></div>
        <p></p>
        </div>
        <div class="wp-block-column">
        <h3>Lorem ipsum dolor sit amet</h3>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec eget molestie erat. Nunc ultricies erat eros</p>
        <div class="wp-block-cdm-highlightbullet-highlightbullet"><aside class="cdm-highlight-bullet" data-test="highlight_bullet"><a href="https://www.lowermybills.com/">Pellentesque cursus ex non ligula tincidunt semper</a></aside></div>
        </div>
        </div>
        </section>

        <section class="wp-block-group lmb-blockpattern-find-refinance lmb-wide-block">
        <div class="wp-block-group__inner-container">
            <h2>Find a Refinance Lender </h2>
            <img
                class="cdm-ornamental-image-img wp-block-cdm-ornamentalimage-ornamentalimage"
                data-test="ornamental_image_img"
                data-fullurl="https://loremflickr.com/800/533/illustration,dog/all"
                src="https://loremflickr.com/800/533/illustration,dog/all"
                alt="Homeowner and inspector examine a home's interior window."
            />

            <div class="wp-block-group lmb-find-refinance-subtitle">
                <div class="wp-block-group__inner-container">
                    <div class="wp-block-group__inner-container">
                        <p>The right refinance lender can help you reduce what you’re paying for your mortgage. Get started with LowerMyBills.
                        </p>

                        <div class="wp-block-buttons lmb-cta-buttons-large">
                            <div class="wp-block-button">
                                <a class="wp-block-button__link">COMPARE MORTGAGES</a>
                            </div>
                        </div>

                        
                    </div>
                </div>
            </div>
        </div>
        </section>
        <div>
        <h2>Common Homebuying Questions</h2>
        <h3>How much money do you need to buy a house? </h3>
        <p>To get a mortgage and buy a house, you’ll typically pay a percentage of the purchase price upfront. The 20% down payment is a well-known rule of thumb — and a significant hurdle to homeownership — but it’s also a myth.  In fact, qualified borrowers may put as little as 3% down , and no down payment is required for certain government programs . If you know what loans you’re eligible for, you can determine the down payment you need to buy a home. <p>
        </div>`),
    },
    breadcrumbs: [
        {
            id: 10802,
            term_id: null,
            text: 'Home',
            url: 'https://dev.lowermybills.com/',
        },
        {
            id: 14984,
            term_id: null,
            text: 'Mortgage tools',
            url: 'https://dev.lowermybills.com/mortgage-tools/mortgage-tools/',
        },
    ],
    postsRelated: GridContainerConstants.gridParams.samplePosts,
    headerProps: {
        headerMenu: HeaderConstants.sampleAttributes.headerMenu,
        logoUrl: HeaderConstants.defaultAttributes.url,
        logoAlt: HeaderConstants.defaultAttributes.alt,
    },
    footerProps: {
        itemsMenu: FooterConstants.sampleAttributes.listMenu,
        socialLinks: FooterConstants.sampleAttributes.links,
    },
    labelBtn: 'SEE MORE',
    gridLabel: 'Read More',
};
