import React from 'react';
import PropTypes from 'prop-types';
import * as CSS from './ReadingTime.module.scss';
import { testAttributes } from './Constants';

const ReadingTime = ({ minutes, label, verticalBar }) => 
{
    let minutesRounded = typeof minutes === 'string' ? parseFloat(minutes) : minutes;
    if (Number.isNaN(minutesRounded)) minutesRounded = 0;
    minutesRounded = Math.round(minutesRounded);
    const minutesString = minutesRounded > 1 ? minutesRounded.toString() : '< 1';

    return (
        <span className={`${verticalBar ? CSS.readtime : CSS.simpleReadtime}`} data-test={testAttributes.ELEMENT}>
            {minutesString}
            {' '}
            {label}
        </span>
    );
};

ReadingTime.propTypes = {
    /** Number of minutes; will be rounded to a whole number */
    minutes: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
        .isRequired,
    /** Label that follows the minutes */
    label: PropTypes.string,
    /** verticalBar: will show or hide verticalBar */
    verticalBar: PropTypes.bool,
};

ReadingTime.defaultProps = {
    label: 'min read',
    verticalBar: true,
};

export default ReadingTime;
