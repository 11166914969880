// eslint-disable import/prefer-default-export
import featuredImageFallbackSrc from '../../../static_assets/logo/LMBLogoIcon.svg';

export const defaultTitleStory = 'Story of the Week';
export const defaultTitleVideo = 'Video of the Week';
export const featuredImageFallback = {
    src: featuredImageFallbackSrc,
    alt: 'LowerMyBills Logo',
};
export const testAttributes = {
    LATEST_MEDIA: 'latest_media_block',
    STORY_CARD: 'latest_story_card',
    STORY_THUMBNAIL: 'latest_story_card_thumbnail',
    STORY_CARD_TITLE: 'latest_story_card_title',
    STORY_TITLE: 'latest_story_article_title',
    STORY_AUTHOR: 'latest_story_card_author',
    VIDEO_CARD: 'latest_video_card',
    VIDEO_CARD_TITLE: 'latest_video_card_title',
    VIDEO_TITLE: 'latest_video_item_title',
}