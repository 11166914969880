import React from 'react';
import PropTypes from 'prop-types';
import { testAttributes } from '../Constants';
import {
    LatestMediaCard
} from '../Cards.module.scss';
import {
    VideoCardContainer
} from './VideoCard.module.scss'


const VideoCard = ({title, player, titleVideo}) => (
    <div
        className={`${LatestMediaCard} ${VideoCardContainer}`}
        itemScope
        itemType="https://schema.org/VideoObject"
        data-test={testAttributes.VIDEO_CARD}
    >
        <h2 data-test={testAttributes.VIDEO_CARD_TITLE}>{titleVideo}</h2>
        {player}
        <h3 itemProp="headline" data-test={testAttributes.VIDEO_TITLE}>{title}</h3>
    </div>
)

VideoCard.propTypes = {
    /** Title = title of the embedded video */
    title: PropTypes.string.isRequired,
    /** player = embedded video player */
    player: PropTypes.element.isRequired,
    /** Video Card title, e.g. 'Video of the Week' */
    titleVideo: PropTypes.string.isRequired
}

export default VideoCard;