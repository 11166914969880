import DOMPurify from 'isomorphic-dompurify';

import parseHTML from '../../../utilities/parseHTML';

import { fnParseWith } from '../../../utilities/parses';
import { fnFootnoteParser } from '../../PostContainer/helpers/functionsParse';
import toLatestMediaParser from '../../../utilities/parses/parseWith/toLatestMediaParser';
import { filterAndSortRelatedPosts } from '../../../utilities/gridCardFilters';

import processHeadArgs from '../../common/process/processHeadArgs';
import {btnLabel} from '../Constants';

const processPostArgs = (queried, location, additionalParsers, pageContext) => 
{
    const { post, relatedPosts, relatedGuides } = queried;

    const parsedTitle = parseHTML(post.title);
    const postArgs = {};
    postArgs.btnLabel = btnLabel;
    postArgs.databaseId = post.databaseId;
    postArgs.showBreadCrumbs = post.uri !== '/';
    postArgs.isHomepage = post.uri === '/';
    postArgs.showPostGrid =
        queried?.post?.cdmfields?.pagesettings?.show_page_grid;
    postArgs.postsRelated = filterAndSortRelatedPosts(
        post.databaseId,
        [...relatedPosts.edges, ...relatedGuides.edges],
        []
    );
    const parserLatestStory = postArgs.postsRelated?.[0] || {};
    const parserLatestVideo = pageContext.siteMedia?.video?.latest || {};

    const fnLatestMediaParser = toLatestMediaParser({
        story: parserLatestStory,
        video: parserLatestVideo,
    });

    const [responseParse, postContent] = fnParseWith(post.content, {
        ...additionalParsers,
        footnoteParser: fnFootnoteParser,
        latestMedia: fnLatestMediaParser,
    });
    const listArticles = responseParse.footnoteParser.return.articleSourceList();

    postArgs.responseParse = responseParse;

    postArgs.infoPost = {
        title: parsedTitle,
        content: postContent,
    };
    postArgs.breadcrumbs = post.cdmfields.breadcrumb.map((crumb) => ({
        id: crumb.id,
        text: parseHTML(crumb.text),
        url: DOMPurify.sanitize(crumb.url),
    }));

    const propsContent =
        typeof postContent === 'object' &&
        postContent?.find((item) => item?.props);

    postArgs.showTitle = propsContent
        ? !propsContent?.props?.className?.includes(
              'lmb-blockpattern-title-cluster'
          ) &&
          !propsContent?.props?.className?.includes(
              'lmb-blockpattern-fullwidth-title-cluster'
          )
        : true;

    postArgs.fullContainer = propsContent
        ? propsContent?.props?.className?.includes('lmb-wide-block')
        : false;

    postArgs.fullPageWidth = propsContent
        ? propsContent?.props?.className?.includes('lmb-fullwidth-block')
        : false;

    postArgs.articleSourceList = listArticles;
    return postArgs;
};

const processQueried = (
    queried,
    location,
    additionalParsers = {},
    pageContext = {}
) => 
{
    const allArgs = {
        postArgs: processPostArgs(
            queried,
            location,
            additionalParsers,
            pageContext
        ),
        headArgs: processHeadArgs(
            queried?.post?.cdmfields?.metadata,
            queried.site.siteMetadata
        ),
    };
    return allArgs;
};

export default processQueried;
